exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alliance-partner-index-jsx": () => import("./../../../src/pages/alliance/partner/index.jsx" /* webpackChunkName: "component---src-pages-alliance-partner-index-jsx" */),
  "component---src-pages-alliance-property-index-jsx": () => import("./../../../src/pages/alliance/property/index.jsx" /* webpackChunkName: "component---src-pages-alliance-property-index-jsx" */),
  "component---src-pages-company-message-index-jsx": () => import("./../../../src/pages/company/message/index.jsx" /* webpackChunkName: "component---src-pages-company-message-index-jsx" */),
  "component---src-pages-company-mission-index-jsx": () => import("./../../../src/pages/company/mission/index.jsx" /* webpackChunkName: "component---src-pages-company-mission-index-jsx" */),
  "component---src-pages-company-profile-index-jsx": () => import("./../../../src/pages/company/profile/index.jsx" /* webpackChunkName: "component---src-pages-company-profile-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-op-index-jsx": () => import("./../../../src/pages/contact-op/index.jsx" /* webpackChunkName: "component---src-pages-contact-op-index-jsx" */),
  "component---src-pages-contact-other-index-jsx": () => import("./../../../src/pages/contact-other/index.jsx" /* webpackChunkName: "component---src-pages-contact-other-index-jsx" */),
  "component---src-pages-contact-pr-index-jsx": () => import("./../../../src/pages/contact-pr/index.jsx" /* webpackChunkName: "component---src-pages-contact-pr-index-jsx" */),
  "component---src-pages-contact-property-index-jsx": () => import("./../../../src/pages/contact-property/index.jsx" /* webpackChunkName: "component---src-pages-contact-property-index-jsx" */),
  "component---src-pages-contact-service-index-jsx": () => import("./../../../src/pages/contact-service/index.jsx" /* webpackChunkName: "component---src-pages-contact-service-index-jsx" */),
  "component---src-pages-contact-store-index-jsx": () => import("./../../../src/pages/contact-store/index.jsx" /* webpackChunkName: "component---src-pages-contact-store-index-jsx" */),
  "component---src-pages-draft-news-index-jsx": () => import("./../../../src/pages/draft/news/index.jsx" /* webpackChunkName: "component---src-pages-draft-news-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-news-strapi-okulab-hp-post-slug-jsx": () => import("./../../../src/pages/news/{StrapiOkulabHpPost.slug}.jsx" /* webpackChunkName: "component---src-pages-news-strapi-okulab-hp-post-slug-jsx" */),
  "component---src-pages-owners-support-contact-index-jsx": () => import("./../../../src/pages/owners-support/contact/index.jsx" /* webpackChunkName: "component---src-pages-owners-support-contact-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-services-fc-index-jsx": () => import("./../../../src/pages/services/fc/index.jsx" /* webpackChunkName: "component---src-pages-services-fc-index-jsx" */),
  "component---src-pages-services-machine-index-jsx": () => import("./../../../src/pages/services/machine/index.jsx" /* webpackChunkName: "component---src-pages-services-machine-index-jsx" */),
  "component---src-pages-services-startup-index-jsx": () => import("./../../../src/pages/services/startup/index.jsx" /* webpackChunkName: "component---src-pages-services-startup-index-jsx" */),
  "component---src-pages-services-support-index-jsx": () => import("./../../../src/pages/services/support/index.jsx" /* webpackChunkName: "component---src-pages-services-support-index-jsx" */)
}

