import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
require("styles/index.scss")
require("react-responsive-carousel/lib/styles/carousel.css")

export const onClientEntry = () => {
  // IntersectionObserver polyfill for Safari, IE
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}

export const wrapRootElement = ({ element }) => {
  const siteKey = process.env.RECAPTCHA_SITE_KEY
  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey} data-size="invisible">
      {element}
    </GoogleReCaptchaProvider>
  )
}
